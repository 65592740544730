
import './index.css'

const loader = document.getElementsByClassName('loader')[0]

fetch('https://feeds.behold.so/ejjiJfEapEAiaR6ZLdxc')
  .then(data => data.json())
  .then(photos => {
    gsap.to(loader, {opacity: 0, duration: 0.5})
    const container = document.getElementsByClassName('comics')[0]
    photos.forEach(({
      id,             // The post ID
      mediaUrl,       // The image source
      permalink,      // URL of the Instagram post
      caption,        // Post caption
      mediaType,      // 'IMAGE', 'VIDEO', or 'CAROUSEL_ALBUM'
      thumbnailUrl,   // Only returned for video posts
      timestamp,      // Post publish date,
      children        // An array of CAROUSEL_ALBUM children. Each with id, mediaUrl, and mediaType
    }) => {
        const a = document.createElement('a');
        a.href = permalink;
        var d = document.createElement('div');
        d.classList.add('comic-container')
        a.appendChild(d);
        if(children) {
          for(i = 0; i < children.length; i++){
            img = document.createElement('img'); 
            img.src = children[i].mediaUrl;
            img.classList.add('comic');
            d.appendChild(img);
          }
        }
        else {
          var img = document.createElement('img'); 
          img.src = mediaUrl;
          img.classList.add('comic');
          d.appendChild(img);
        }
        const t = document.createElement('div');
        t.classList.add('comic-caption');
        t.innerHTML = caption;
        a.appendChild(t);
        container.appendChild(a);
        if(children) {
          d = document.createElement('div');
          d.classList.add('scroll');
          img = document.createElement('img'); 
          img.src = 'assets/arrow.gif';
          d.appendChild(img);
          const span = document.createElement('span');
          span.innerHTML = children.length + ' panels';
          d.appendChild(span);
          t.appendChild(d);
        }
    });
  });